import React from "react"

import SEO from "/components/seo"
import CDSci from "components/Holiday2020/Nike/CDSci"

const NikecdsciHoliday2020 = () => (
  <>
    <SEO
      title="Thesis | Holiday 2020: Nike CDsci"
      description="Happy holidays from Thesis, a full-service digital agency in Portland, OR where strategy, creative, tech, and partner services combine to humanize the digital relationship between brands and people. Using data as the driver, our versatile work has the range to meet people where they are and the relevance to engage people as they are."
    />
    <CDSci />
  </>
)

export default NikecdsciHoliday2020
