import React from "react"

import styles from "./styles.module.less"

import SimpleParagraph from "../../SimpleParagraph"
import img from "./../img/green.svg"

const GreenBody = props => {
  return (
    <div className={styles.BodyHl}>
      <div className={styles.preheader}>
        <img src={img} alt="" />
        <p>{props.preheader}</p>
        <img src={img} alt="" />
      </div>
      <SimpleParagraph headline={props.headline} par={props.par} />
    </div>
  )
}

export default GreenBody
