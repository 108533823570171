import React from "react"

import styles from "./styles.module.less"

import SimpleParagraph from "../../SimpleParagraph"
import img from "./../img/tan.svg"

const TanBody = props => {
  return (
    <div className={styles.BodyHl}>
      <div className={styles.preheader}>
        <img src={img} alt="" />
        <p>{props.preheader}</p>
        <img src={img} alt="" />
      </div>
      <SimpleParagraph headline={props.headline} par={props.par} />
      {/* <h1>{props.header}</h1>
        <p>{props.paragraph}</p> */}
    </div>
  )
}

export default TanBody
